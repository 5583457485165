define("discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-similar-topic", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <a href={{this.topic.url}} target="_blank" rel="noopener noreferrer">
    <span class="title">{{html-safe this.topic.fancy_title}}</span>
    <div class="blurb"><RelativeDate @date={{@topic.created_at}} />
      -
      {{html-safe this.topic.blurb}}</div>
  </a>
  */
  {
    "id": "0evUtzYc",
    "block": "[[[10,3],[15,6,[30,0,[\"topic\",\"url\"]]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[1,\"\\n  \"],[10,1],[14,0,\"title\"],[12],[1,[28,[35,0],[[30,0,[\"topic\",\"fancy_title\"]]],null]],[13],[1,\"\\n  \"],[10,0],[14,0,\"blurb\"],[12],[8,[39,1],null,[[\"@date\"],[[30,1,[\"created_at\"]]]],null],[1,\"\\n    -\\n    \"],[1,[28,[35,0],[[30,0,[\"topic\",\"blurb\"]]],null]],[13],[1,\"\\n\"],[13]],[\"@topic\"],false,[\"html-safe\",\"relative-date\"]]",
    "moduleName": "discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-similar-topic.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});